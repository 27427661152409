import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { TypographyText } from "../Reusable/Reusable";
import img1 from "../Assets/pexels-jay-jay-redelinghuys-8724474.jpg";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import "aos/dist/aos.css";
import imgw from "../Assets/VP Logo_page-0001.jpg";

const Section2 = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [isContentVisible, setIsContentVisible] = useState(false);

  useEffect(() => {
    // Simulate content loading delay (you can replace this with your actual data loading logic)
    const timer = setTimeout(() => {
      setIsContentVisible(true);
    }, 1);

    return () => clearTimeout(timer);
  }, []);
  console.log(isContentVisible, "aaa");
  return (
    <>
      <Grid
        container
        spacing={2}
        className="large-header"
         sx={{ height: !isSmallScreen ? "105vh" : "auto" }}
      >
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          // sx={{ margin: "auto" }}
          sx={{
            backgroundImage: `url(${img1})`,
            backgroundSize: "cover",
            objectFit: "cover",
            height: "100%",
            display: "flex",
            alignItems: "center",
            backgroundRepeat:'no-repeat',
          }}
        >
          <Box sx={{ margin: "auto", p: "8%" }}>
            <TypographyText
              textAlign="centre"
              color="#4B573F"
              fontWeight="bolder"
              variant={!isSmallScreen ? "h4" : "h5"}
              Typography={
                <>
                  Elevate Your Senses with Verona Perfumes Industry: Where
                  Fragrance Meets Artistry
                </>
              }
            />
            <br /> <br />
            <TypographyText
              Typography={
                <>
                  Welcome to Verona Perfumes Industry Perfumes, where the art of
                  scent meets the essence of individuality. Founded in [year],
                  we take pride in curating an exceptional collection of
                  perfumes that transcend the ordinary. Our fragrances are not
                  just scents; they are a journey into the extraordinary,
                  crafted with precision, passion, and a commitment to quality.
                  At Verona Perfumes Industry, we believe that every drop tells
                  a unique story, reflecting the diverse personalities and
                  lifestyles of our discerning customers. Join us in the pursuit
                  of olfactory excellence, where each bottle is a testament to
                  the harmonious blend of artistry and craftsmanship.
                </>
              }
              textAlign="centre"
              color="#231F1E"
              fontSize="1.1rem"
            />
          </Box>
        </Grid>
        {/* <Grid item xs={12}>
          <img src={imgw} alt="" width={'100%'}/>
        </Grid> */}
      </Grid>

      {/************************************************* */}
    </>
  );
};

export default Section2;
