import { Box, Grid } from "@mui/material";
import React from "react";
import bg2 from "../Assets/pexels-valeriia-miller-3910069.jpg";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { TypographyText } from "../Reusable/Reusable";
const Homesection1 = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      {/* <Grid container>
        <Grid xs={12} md={12} lg={12} sm={12} className="large-header"
        sx={{height:!isSmallScreen ? "105vh" : "auto",}}>
          <video
            autoPlay
            loop
            muted
            playsInline
            style={{
              // position: 'absolute',
              width: "100%",
              height: "100%",
              objectFit: "cover",
              zIndex: "-1",
            }}
          >
            <source src={bg2} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Grid>
      </Grid> */}


      <Grid container>
        <Grid item xs={12}>
          <Box
            sx={{
              backgroundImage: `url(${bg2})`,
              backgroundSize: "cover",
              backgroundPosition: "centre",
              backgroundRepeat:'no-repeat',
              height: "105vh",
            }}
          >
            <div data-aos="fade-up">
              {/* <TypographyText
                Typography={<>Contact Us</>}
                fontWeight="bolder"
                variant={!isSmallScreen ? "h3" : "h4"}
                textAlign="centre"
                color="#fff"
                paddingTop={isSmallScreen ? "100px" : "250px"}
              /> */}
            </div>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Homesection1;
