import React from 'react'
import Privsection1 from './Privsection1'

const Privatelabelling = () => {
  return (
<>
<Privsection1/></>
  )
}

export default Privatelabelling
