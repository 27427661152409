import logo from "./logo.svg";
import "./App.css";
import Navigate from "./Navigate/Navigate";

function App() {
  return (
    <div className="App">
      <Navigate />
    </div>
  );
}

export default App;
